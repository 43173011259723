import React, {useEffect, useMemo, useState} from 'react';

import CloseIcon from '../../../assets/imgs/PaintBar/close-icon.svg';
import DeleteIcon from '../../../assets/imgs/PaintBar/delete-trash-icon.svg';
import SaveIcon from '../../../assets/imgs/PaintBar/save-comment-box-icon.svg';
import "./style.css";
import {color_picker_data} from "../../../utils/color_picker_data";
import {setCommentToolboxState} from "../../../store/actions/painterStart";
import ColorCircle from "../components/ColorCircle";

const PopUpCommentBox = (props) => {
    const {
        handleClose,
        handleDelete,
        handleSave,
        description,
        color,
        setTextForMarker,
        setColorForMarker,
    } = props

    const [text, setText] = useState('');
    const [commentColor, setCommentColor] = useState(color);
    const [isOpenColors, setIsOpenColors] = useState(false);
    const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);
    const [colorsForCommentBoxColorPicker, setColorsForCommentBoxColorPicker] = useState([
        { id: 1, color: "#000000", status: 0 },
        { id: 2, color: "#2C476C", status: 1 },
        { id: 3, color: "#157EFA", status: 0 },
        { id: 4, color: "#53D669", status: 0 },
        { id: 5, color: "#FED031", status: 0 },
        { id: 6, color: "#FC3142", status: 0 },
        { id: 7, status: 0 },
    ]);

    const changeColorsForCommentBoxColorPicker = (colorHEX) => {
        const updatedColors = colorsForCommentBoxColorPicker.map(color => {
            if (color.color === colorHEX) {
                return { ...color, status: 1 };
            } else {
                return { ...color, status: 0 };
            }
        });
        setColorsForCommentBoxColorPicker(updatedColors);
    };

    const handleTextareaText = (e) => {
        setText(e.target.value);
    };

    const handleSaveWrapper = () => {
        setTextForMarker(text);
        setColorForMarker(commentColor);
        handleSave(text, commentColor);
    };

    const handleCloseWrapper = () => {
        handleClose();
        text ? handleSaveWrapper() : handleDelete();
    };

    useEffect(() => {
        if (description) {
            setText(description);
            setTextForMarker(description);
        }

        if (color) {
            setCommentColor(color);
            setColorForMarker(color);
        }
    }, [description, color]);

    useEffect(() => {
        handleSaveWrapper();
    }, [text, commentColor]);

    const toggleColorsDropdown = () => {
        if (isOpenColorPicker && isOpenColors) {
            setIsOpenColorPicker(false);
        }
        setIsOpenColors(!isOpenColors);
    };

    const colorSelectHandler = (e, selectedColor) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedColor.id === colorsForCommentBoxColorPicker.length) {
            setIsOpenColorPicker(!isOpenColorPicker);
            return;
        }

        setColorForMarker(selectedColor.color);
        setCommentColor(selectedColor.color);
        changeColorsForCommentBoxColorPicker(selectedColor.color);
    };

    useEffect(() => {
        changeColorsForCommentBoxColorPicker(commentColor);
    }, [commentColor]);

    return (
        <div className={'popUp-container'}>
            {isOpenColors &&
                <div className={'color-picker-popup'}>
                    {colorsForCommentBoxColorPicker.map((color, i) => (
                        <div
                            key={i}
                            className="comment_box_tool_color_data_item"
                            onClick={(e) => colorSelectHandler(e, color)}
                        >
                            {i === colorsForCommentBoxColorPicker.length - 1 ? (
                                <div className={"color_circle"}>
                                    <svg
                                        style={{
                                            background: "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                            borderRadius: "50%",
                                            height: 20,
                                            stroke: color.status ? '#3064ab' : '',
                                            strokeWidth: color.status ? "2" : "0",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                    />
                                </div>
                            ) : (
                                <div className={"comment-box-available-color"}>
                                    <ColorCircle isPaint={true}
                                                 color={color}
                                                 status={color.status}
                                                 updateColorStatus={() => {}}/>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            }
            {isOpenColorPicker && (
                <div
                    className="comment_box_tools_color_item_container"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    {color_picker_data.map((color, i) => (
                        <div
                            key={i}
                            className="comment_box_tools_color_item"
                            style={{ backgroundColor: color }}
                            onClick={() => {
                                setColorForMarker(color);
                                setCommentColor(color);
                                changeColorsForCommentBoxColorPicker(null);
                                setIsOpenColorPicker(false);
                            }}
                        ></div>
                    ))}
                    <span></span>
                </div>
            )}
            <div className={'edit-popup-header'}>
                <div className={'popUp-actions'}>
                    <div className={'color-picker-wrapper'}>
                        <div className={'color-picker-comment-box'}>
                            <div className='comment-box-popup-container_color-picker' onClick={toggleColorsDropdown}>
                                <span className={"current-picked-color-comment-box"} onClick={toggleColorsDropdown}>
                                    <svg
                                        style={{ stroke: '#3064ab' }}
                                        strokeWidth="3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 28 28"
                                        fill={commentColor}
                                    >
                                        <circle
                                            cx="14"
                                            cy="14"
                                            r="11.5"
                                            stroke={'#fff'}
                                            strokeWidth="2" e
                                        />
                                        {/* MAYBE BE NEEDED */}

                                        {/*<circle*/}
                                        {/*    cx="14"*/}
                                        {/*    cy="14"*/}
                                        {/*    r="10.5"*/}
                                        {/*    stroke={commentColor}*/}
                                        {/*/>*/}
                                        {/*<circle*/}
                                        {/*    cx="14"*/}
                                        {/*    cy="14"*/}
                                        {/*    r="9"*/}
                                        {/*    fill={commentColor}*/}
                                        {/*/>*/}

                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <span className={'popUp-delete'} onClick={handleDelete}>
                        <img src={DeleteIcon} alt="" />
                    </span>
                    <span className={'popUp-close'} onClick={handleCloseWrapper}>
                        <img src={CloseIcon} alt="" />
                    </span>
                </div>
            </div>
            <div className={'popUp-editor'}>
                <div className={'popUp-counter'}>
                    <span>{text.length} / 400</span>
                </div>
                <textarea maxLength="400" rows="4" cols="50" autoFocus={true} value={text} onInput={handleTextareaText} />
            </div>
        </div>
    )
};

export default PopUpCommentBox;
