import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import './style.css';

const TextBoxEditor = (props) => {
    const {
        checkCharacterCount,
        id,
        editorRef,
        text,
        setText,
        isEditing,
    } = props;

    useEffect(() => {
        const quillInstance = editorRef.current.getEditor();
        const editorContainer = quillInstance.root;

        const stopEventPropagation = (event) => {
            event.stopPropagation();
        };

        const handleDoubleClick = (event) => {
            event.stopPropagation();
            quillInstance.focus();
            quillInstance.setSelection(0, quillInstance.getLength());
        };

        const handleArrowKeys = (event) => {
            if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                event.stopPropagation();
            }
        };

        if (isEditing) {
            editorContainer.addEventListener('mousedown', stopEventPropagation);
            editorContainer.addEventListener('touchstart', stopEventPropagation);
            editorContainer.addEventListener('dblclick', handleDoubleClick);
            editorContainer.addEventListener('keydown', handleArrowKeys);
        } else {
            editorContainer.removeEventListener('mousedown', stopEventPropagation);
            editorContainer.removeEventListener('touchstart', stopEventPropagation);
            editorContainer.removeEventListener('dblclick', handleDoubleClick);
            editorContainer.removeEventListener('keydown', handleArrowKeys);
        }

        return () => {
            editorContainer.removeEventListener('mousedown', stopEventPropagation);
            editorContainer.removeEventListener('touchstart', stopEventPropagation);
            editorContainer.removeEventListener('dblclick', handleDoubleClick);
            editorContainer.removeEventListener('keydown', handleArrowKeys);
        };
    }, [editorRef, isEditing]);

    return (
        <ReactQuill
            onKeyDown={checkCharacterCount}
            className={`quill-text-tool-${id}`}
            ref={editorRef}
            theme='snow'
            value={text}
            onChange={setText}
            modules={{
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike']
                ],
            }}
        />
    );
}

export default TextBoxEditor;
